import React from 'react';
// import styled, { css } from "styled-components";

import './card.css';

import spread from '../assets/butter_spread_long.png';

function Card (props){
  return (
    <div className='card'>
      <div className='title'>{ props.title }</div>
      <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
      {props.body.map(aDiv => {
        return (
          <div className='cardBody'>{aDiv}</div>
        )})}
    </div>
  )
}

export default Card;
