import React from 'react';
import spread from '../assets/butter_spread_long.png';
import bird from '../assets/Twitter social icons - square - white.png';
import insta from '../assets/Instagram_Glyph_White.png';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-class">
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
        <p className="yellow">
          . you can find us .
          <table align='center'>
            <tr>
          <td style={{padding: '15px'}}><a href={'https://twitter.com/ButterImage'}><img src={bird} style={{padding: "10px"}} height="50px" alt="twitter logo" /><br/>@ButterImage</a></td>
          <td style={{padding: '15px'}}><a href={'https://instagram.com/butterimagegt'}><img src={insta} style={{padding: "10px"}} height="50px" alt="instagram logo" /><br/>@ButterImage</a></td>
            </tr>
          </table>
        </p>
        <table className="center-content">
          <tr>
            <td>
            <subp>See the world butter.</subp><br/>
            </td>
            <td>
            <subp>est 2022</subp><br/>
            </td>
          </tr>
          <tr>
            <td>
            <subp>News</subp><br/>
            </td>
            <td>
            <subp>Misc</subp><br/>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default Footer
