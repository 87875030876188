import React from 'react';
import { Link } from 'react-router-dom';
import './nav.css';

class Nav extends React.Component {

  state = {

  }

  render() {
    return (
      <div className='nav-div'>
        <div className='nav-bar'>
          <ul>
            <li className='nav-link'><Link to="/">home</Link></li>
            <li className='nav-link'>experience
              <ul className='nav-dropdown'>
                <li className='nav-link'><a href="https://sight.butterimage.com">sight</a></li>
                <li className='nav-link'><a href="https://sound.butterimage.com">sound</a></li>
                <li className='nav-link nav-inactive'>smell</li>
                <li className='nav-link'><a href="https://touch.butterimage.com">touch</a></li>
                <li className='nav-link nav-inactive'>taste</li>
              </ul>
            </li>
            <li className='nav-link'><Link to="/about">about</Link></li>
          </ul>
        </div>
        {/* <a className="nav-link" href="https://sight.butterimage.com">Sight</a>| */}
        {/* <Link className="nav-link" to="/taste">Taste</Link>| */}

      </div>
    );
  }
}

export default Nav
