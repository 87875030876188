import React from 'react';

class ComingSoon extends React.Component {
  render() {
    return (
      <splash>
        We are churning to bring you fresh [pagecomponent]
      </splash>
    );
  }
}

export default ComingSoon
