import React from 'react';
import Card from '../components/card';

class About extends React.Component {
    title00=[". about . you stopped by . thanks ."];
    body00=[
        ". here at butter image, we want to bring you the resources to put those final "
        + "touches on whatever you are working on . "
        + "feel free to use the resources you like in your project . "
        + "we would appreciate it if you spread the word about us .",
        ". all sight and sound resources are free for you to use in your projects ."
        + " all touch articles are free for you to read in your eyes .",
        ". sound . here you can find the best background loops on the site . use them in your elevator, youse them in your video, use them as ringtones . use them for me please .",
        ". sight . this is where you can grab up patterns, images, screencaps, and anythig visual .",
        ". touch . now we are getting weird . this space is intended as a blog on making things, building things, learning things ."
    ];

  render() {
    return (
      <div>
        <Card title={this.title00} body={this.body00}/>

      </div>
    );
  }
}

export default About
