import React from "react";
import axios from "axios";

class Slogan extends React.Component{
  state= {
    slogan: "loadingMessage"
  }
  componentDidMount() {
    axios.get('https://six.butterimage.com/slogan.php')
      .then(response=>{
        this.setState({slogan: response.data})
      });
  }

  render () {
    return <splash>{this.state.slogan}</splash>
  }
}
export default Slogan
