import { Component } from "react";
import './App.css';
import ReactGA from 'react-ga4';
import Slogan from './base-elements/slogan';

import spread from './assets/butter_spread_long.png';

class App extends Component {

  initReactGA = () => {
    ReactGA.initialize('G-9L4WNFRVG9');
    ReactGA.send('pageview');
    ReactGA.send('BUTTER');
    ReactGA._gaCommandSendPageviewParameters('BUTTER_PARAM');
  };
  componentDidMount(){
    this.initReactGA();
  }
  render() {
    return (
      <div className="App">
        <h1>. butter . image . dot . com .</h1>
        <Slogan />
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
      </div>
    );
  }
}

export default App;
